import { useEffect, useState } from 'react'
import { Button } from "./components/ui/button"
import { Input } from "./components/ui/input"
import { Label } from "./components/ui/label"
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "./components/ui/card"
import { AlertCircle, Loader2, Phone, CheckCircle } from "lucide-react"
import { Alert, AlertDescription, AlertTitle } from "./components/ui/alert"
import supabase from './utils/supabase'
import { useNavigate } from 'react-router-dom'
import { sendVerificationCode, validateCode } from './services/user-service'

export default function PhoneVerification() {
  const [phoneNumber, setPhoneNumber] = useState('')
  const [verificationCode, setVerificationCode] = useState('')
  const [step, setStep] = useState<'phone' | 'code'>('phone')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [success, setSuccess] = useState(false)
  const [session, setSession] = useState<any | null>(null)
  const navigate = useNavigate();

  useEffect(() => {
    
    supabase.auth.getSession().then(({ data: { session } }) => {
      if (!session) {
        navigate('/login')
      }
      setSession(session)
    })

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      if (!session) {
        navigate('/login')
      }
      setSession(session)
    })

    return () => subscription.unsubscribe()
  }, [navigate])

  const handleSendCode = async (e: React.FormEvent) => {
    e.preventDefault()
    setLoading(true)
    setError(null)
    try {
      await sendVerificationCode(session?.access_token, `55${phoneNumber}`);
      setStep('code');
    } catch (error) {
      setError('Falha ao enviar o código de verificação.')
      console.error('Error sending verification code:', error)
    } finally {
      setLoading(false)
    }
    setLoading(false);
  }

  const handleVerifyCode = async (e: React.FormEvent) => {
    e.preventDefault()
    setLoading(true)
    setError(null)
    try {
      await validateCode(session?.access_token, `55${phoneNumber}`, verificationCode);
      setSuccess(true);
      navigate('/dashboard');
    } catch (error) {
      setError('Código incorreto.')
      console.error('Error validating verification code:', error)
    } finally {
      setLoading(false)
    }
    setLoading(false)
  }

  return (
    <div className="flex min-h-screen items-center justify-center bg-[#2D1F2F] p-4">
      <Card className="w-full max-w-md bg-white/10 text-white">
        <CardHeader>
          <CardTitle className="text-2xl font-bold">Conecte seu celular</CardTitle>
          <CardDescription className="text-gray-300">
            {step === 'phone' 
              ? 'Digite seu número de telefone para receber um código de verificação'
              : 'Digite o código de verificação enviado para seu telefone'}
          </CardDescription>
        </CardHeader>
        <CardContent>
          {step === 'phone' ? (
            <form onSubmit={handleSendCode}>
              <div className="space-y-4">
                <div className="space-y-2">
                  <Label htmlFor="phone-number">Número de telefone</Label>
                  <div className="flex">
                    <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-600 bg-gray-700 text-gray-300 text-sm">
                      +55
                    </span>
                    <Input
                      id="phone-number"
                      type="tel"
                      placeholder="(11) 98765-4321"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      className="rounded-l-none text-black"
                      required
                    />
                  </div>
                </div>
                <Button className="w-full bg-[#80DEEA] hover:bg-[#4FC3F7] text-[#2D1F2F]" type="submit" disabled={loading}>
                  {loading ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : <Phone className="mr-2 h-4 w-4" />}
                  Enviar código
                </Button>
              </div>
            </form>
          ) : (
            <form onSubmit={handleVerifyCode}>
              <div className="space-y-4">
                <div className="space-y-2">
                  <Label htmlFor="verification-code">Código de verificação</Label>
                  <Input
                    id="verification-code"
                    type="text"
                    placeholder="123456"
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                    className='text-black'
                    required
                  />
                </div>
                <Button className="w-full bg-[#80DEEA] hover:bg-[#4FC3F7] text-[#2D1F2F]" type="submit" disabled={loading}>
                  {loading ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : <CheckCircle className="mr-2 h-4 w-4" />}
                  Verificar código
                </Button>
              </div>
            </form>
          )}
        </CardContent>
        <CardFooter>
          {error && (
            <Alert variant="destructive">
              <AlertCircle className="h-4 w-4" />
              <AlertTitle>Erro</AlertTitle>
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}
          {success && (
            <Alert>
              <CheckCircle className="h-4 w-4" />
              <AlertTitle>Sucesso</AlertTitle>
              <AlertDescription>Seu número de telefone foi verificado com sucesso!</AlertDescription>
            </Alert>
          )}
        </CardFooter>
      </Card>
    </div>
  )
}