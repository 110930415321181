import { Button } from "./components/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "./components/ui/card"
import { CheckCircle, MessageCircle, Brain, Zap } from "lucide-react"

import logo from './logo.webp';


function App() {

  const whatsappLink = 'https://wa.me/553131910997?text=Oi';

    return (
      <div className="flex flex-col min-h-screen bg-[#2D1F2F] text-white">
      <header className="px-4 lg:px-6 h-20 flex items-center border-b border-white/10">
        <div className="flex items-center gap-2">
        <img
            src={logo}
            alt="Palavras Logo"
            width={40}
            height={40}
            className="rounded-lg"
          />
          <h1 className="text-2xl font-bold text-white">Palavras</h1>
        </div>
        <nav className="ml-auto flex gap-4 sm:gap-6">
          <a className="text-sm font-medium hover:text-[#80DEEA] transition-colors" href="#features">
            Funcionalidades
          </a>
          <a className="text-sm font-medium hover:text-[#80DEEA] transition-colors" href="#pricing">
            Planos
          </a>
          <a className="text-sm font-medium hover:text-[#80DEEA] transition-colors" href="/dashboard">
            Painel
          </a>
        </nav>
      </header>
      <main className="flex-1">
      <section className="w-full py-12 md:py-24 lg:py-32 xl:py-48">
          <div className="container mx-auto px-4 md:px-6">
            <div className="flex flex-col items-center space-y-4 text-center">
              <div className="space-y-2">
                <h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl lg:text-6xl/none">
                  Desafie sua mente com Palavras
                </h1>
                <p className="mx-auto max-w-[700px] text-gray-300 md:text-xl">
                  O jogo de WhatsApp que testa sua habilidade de combinar palavras. Agrupe 16 palavras em 4 categorias e desafie seus amigos!
                </p>
              </div>
              <div className="space-x-4">
                <Button className="bg-[#80DEEA] hover:bg-[#4FC3F7] text-[#2D1F2F]" asChild><a href="#pricing">Comece a jogar</a></Button>
              </div>
            </div>
          </div>
        </section>
        <section id="features" className="w-full py-12 md:py-24 lg:py-32 bg-white/5">
          <div className="container mx-auto px-4 md:px-6">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-center mb-12">
              Por que jogar Palavras?
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              <Card className="bg-white/10 border-white/20">
                <CardHeader>
                  <Brain className="w-12 h-12 text-[#FFD4C2]" />
                  <CardTitle className="text-white">Estimule seu cérebro</CardTitle>
                </CardHeader>
                <CardContent>
                  <p className="text-gray-300">Melhore sua capacidade cognitiva e habilidades de associação de palavras.</p>
                </CardContent>
              </Card>
              <Card className="bg-white/10 border-white/20">
                <CardHeader>
                  <MessageCircle className="w-12 h-12 text-[#FFD4C2]" />
                  <CardTitle className="text-white">Jogue no WhatsApp</CardTitle>
                </CardHeader>
                <CardContent>
                  <p className="text-gray-300">Divirta-se diretamente no seu aplicativo de mensagens favorito.</p>
                </CardContent>
              </Card>
              <Card className="bg-white/10 border-white/20">
                <CardHeader>
                  <Zap className="w-12 h-12 text-[#FFD4C2]" />
                  <CardTitle className="text-white">Desafios diários</CardTitle>
                </CardHeader>
                <CardContent>
                  <p className="text-gray-300">Novos conjuntos de palavras todos os dias para manter você engajado.</p>
                </CardContent>
              </Card>
              <Card className="bg-white/10 border-white/20">
                <CardHeader>
                  <CheckCircle className="w-12 h-12 text-[#FFD4C2]" />
                  <CardTitle className="text-white">Fácil de jogar</CardTitle>
                </CardHeader>
                <CardContent>
                  <p className="text-gray-300">Interface intuitiva e regras simples para uma experiência de jogo suave.</p>
                </CardContent>
              </Card>
            </div>
          </div>
        </section>
        <section id="pricing" className="w-full py-12 md:py-24 lg:py-32">
          <div className="container mx-auto px-4 md:px-6">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-center mb-12">
              Escolha seu plano
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl mx-auto">
              <Card className="flex flex-col justify-between bg-white/10 border-white/20">
                <div>
                  <CardHeader>
                    <CardTitle className="text-2xl text-white">Diário</CardTitle>
                  </CardHeader>
                  <CardContent className="text-white">
                    <p className="text-4xl font-bold mb-4">Gratuito</p>
                    <ul className="space-y-2">
                      <li className="flex items-center">
                        <CheckCircle className="w-5 h-5 mr-2 text-[#80DEEA]" />
                        1 jogo por dia
                      </li>
                      <li className="flex items-center">
                        <CheckCircle className="w-5 h-5 mr-2 text-[#80DEEA]" />
                        Desafios diários
                      </li>
                    </ul>
                  </CardContent>
                </div>
                <CardContent>
                  <Button className="w-full bg-white text-[#2D1F2F] hover:bg-gray-200 font-semibold" asChild>
                    <a href={whatsappLink}>Iniciar Diário</a>
                  </Button>
                </CardContent>
              </Card>
              <Card className="flex flex-col justify-between bg-white/10 border-2 border-[#80DEEA]">
                <div>
                  <CardHeader>
                    <CardTitle className="text-2xl text-white">Diário Ilimitado</CardTitle>
                  </CardHeader>
                  <CardContent className="text-white">
                    <p className="text-4xl font-bold mb-4">R$ 4,99<span className="text-lg font-normal">/mês</span></p>
                    <ul className="space-y-2">
                      <li className="flex items-center">
                        <CheckCircle className="w-5 h-5 mr-2 text-[#80DEEA]" />
                        Todos os jogos do dia
                      </li>
                      <li className="flex items-center">
                        <CheckCircle className="w-5 h-5 mr-2 text-[#80DEEA]" />
                        Desafios diários
                      </li>
                      <li className="flex items-center">
                        <CheckCircle className="w-5 h-5 mr-2 text-[#80DEEA]" />
                        Pontue em todos os jogos
                      </li>
                    </ul>
                  </CardContent>
                </div>
                <CardContent>
                  <Button asChild className="w-full bg-[#80DEEA] hover:bg-[#4FC3F7] text-[#2D1F2F] font-semibold">
                    <a href="/dashboard">Desbloquear ilimitado</a>
                  </Button>
                </CardContent>
              </Card>
              
            </div>
          </div>
        </section>
        <section className="w-full py-12 md:py-24 lg:py-32 bg-gradient-to-r from-[#FFD4C2] to-[#80DEEA]">
          <div className="container mx-auto px-4 md:px-6">
            <div className="flex flex-col items-center space-y-4 text-center">
              <div className="space-y-2">
                <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-[#2D1F2F]">
                  Pronto para desafiar sua mente?
                </h2>
                <p className="mx-auto max-w-[700px] text-gray-800 md:text-xl">
                  Comece a jogar Palavras hoje e veja até onde sua criatividade pode te levar!
                </p>
              </div>
              <div>
                <Button className="bg-[#2D1F2F] text-white hover:bg-[#2D1F2F]/90" asChild>
                  <a href="#pricing">
                  Comece a jogar agora
                  </a>
                </Button>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer className="flex flex-col gap-2 sm:flex-row py-6 w-full shrink-0 items-center px-4 md:px-6 border-t border-white/10">
        <p className="text-xs text-gray-400">© 2024 Palavras. Todos os direitos reservados.</p>
        <nav className="sm:ml-auto flex gap-4 sm:gap-6">
          <a className="text-xs text-gray-400 hover:text-white" href="https://glorious-borogovia-ea6.notion.site/Termos-de-Uso-do-Palavras-12f4102c5a4580b295bad3f3c77235c3?pvs=4">
            Termos de Serviço
          </a>
          <a className="text-xs text-gray-400 hover:text-white" href="https://glorious-borogovia-ea6.notion.site/Pol-tica-de-Privacidade-do-Palavras-12f4102c5a4580eeb341c93645b42790">
            Privacidade
          </a>
        </nav>
      </footer>
    </div>
    )
}

export default App;
